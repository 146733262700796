import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

import "./Footer.css";

export default function Footer() {
    return (
        <footer className="page-footer">
            <Container>
                <Row>
                    <Col xs={12} md={8}>
                        <h6>Contact us</h6>
                        <ul>
                            <li><a href="mailto:info@altfire.co.uk">info@altfire.co.uk</a></li>
                            <li>London: <a href="tel:+4402036334244">+44 (0) 203 633 4244</a></li>
                            <li>Manchester: <a href="tel:+4401613273866">+44 (0) 161 327 3866</a></li>
                        </ul>
                        <p>
                            <LinkContainer to="contact-us"><Button variant="primary">Get in touch</Button></LinkContainer>
                        </p>
                        <p>&copy; 2022 Alt Fire (Company Number 14466130 Registered in England and Wales). All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}